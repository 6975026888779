import React, {useState} from "react"
import styled from "styled-components";
import Layout from "../layout";
import Button from "../components/button";
import DialogBox from "../components/dialog-box";
import TextBox from '../components/text-box';

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
`;
const Dialogs = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  ${({theme}) => theme.mediaQuery.s500} {
    grid-template-columns: repeat(8,1fr);
  }
  max-width: 900px;
  margin: auto;
  padding: 50px 0;
  z-index: 1;
`;
const EasterEgg = styled.div`
  grid-column: 1 / -1;
`;
const StyledIframe = styled.iframe`
  width: 100%;
  aspect-ratio: 16 / 9;
`;
const IndexPage = () => {
  const [showingBehind, showBehind] = useState<boolean>(false);
  return (
    <Layout>
      <Dialogs>
        {!showingBehind && <DialogBox headline='Ya blew it.' closeAction={()=>{showBehind(true)}}>
          <TextBox>
            Looks like you did something dumb and your punishment was ending up here. Bummer!
          </TextBox>
          <ButtonContainer>
            <Button title="Go Home" isLink href="/" >Take me back to paradise city</Button>
          </ButtonContainer>
        </DialogBox>}
        {showingBehind && <EasterEgg><StyledIframe src="https://www.youtube.com/embed/Cipc8EowshY" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" /></EasterEgg>}
      </Dialogs>
    </Layout>
  )
}

export default IndexPage
